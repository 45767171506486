.pickup
    display: flex
    flex-direction: column
    button[type="transparent"]
        height: 3rem
    h1
        font-weight: 600
        font-size: 2.4rem
        line-height: 3.6rem
        color: var(--PropertyTextColorWhite)
        margin-bottom: 1rem
    h5
        margin-top: 1rem
        font-weight: 500
        font-size: 1.4rem
        line-height: 182%
        color: var(--Blue)
    h4
        // margin-bottom: 1rem
        font-weight: 600
        font-size: 1.6rem
        line-height: 182%
        color: var(--PropertyTextColorWhite)
    .checkboxes
        margin-top: 1rem
        display: flex
        flex-direction: column
        gap: .5rem
    .individual
        display: flex
        flex-direction: column
        gap: 1rem
    button
        &:last-child
            margin-top: 1rem