.label
    position: relative
    width: 30.5rem
    font-weight: 600
    font-size: 1.6rem
    line-height: 182%
    color: var(--PropertyTextColorWhite)
.block
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    margin-top: 1rem
    width: 30.5rem
    height: 4.4rem
    outline: none
    background: transparent
    border: .1rem solid var(--InputColorWhite)
    border-radius: .5rem
    &:active
        border: .1rem solid var(--Blue)
    &:focus
        border: .1rem solid var(--Blue)
.calendar
    position: absolute
@media screen and ( max-width: 500px )
    .label, .block
        width: 29.8rem !important
    