.label
    display: flex
    flex-direction: column
    max-width: 24.5rem
    width: 100%
    &-text
        font-weight: 600
        font-size: 1.6rem
        line-height: 182%
        color: var(--PropertyTextColorWhite)
        margin-bottom: 1rem