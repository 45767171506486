.layout
    max-width: 121.6rem
    width: 100%
    margin: auto
    display: flex
    align-items: flex-start
    // justify-content: space-between
    gap: 4.4rem
    // margin-bottom: 10rem
@media screen and (max-width: 930px) 
    .layout
        gap: 2.5rem
@media screen and ( max-width: 500px )
    .layout
        flex-direction: column
    