.book__shipment
    margin-top: 1rem
    display: flex
    align-items: flex-end
    gap: 3rem
    flex-wrap: wrap-reverse
    button
        margin-top: 1.2rem
    .left__side
        label
            margin-bottom: 1rem
        svg
            top: 4.5rem
    .due__now
        transition: .3s
        margin-right: auto
        height: fit-content
        max-width: 50rem
        width: 100%
        display: flex
        flex-direction: column
        padding: 1.4rem 4.5rem
        border-left: .4rem solid var(--Blue)
        background: var(--SecondaryColorDark)
        border-radius: .5rem
        margin-bottom: 1rem
        h1
            margin: 0
            float: left
            color: var(--Blue)
        p
            width: 43.8rem
            display: flex
            flex-wrap: wrap
            font-size: 1.4rem
            line-height: 182%
            color: var(--SecondaryTextColorWhite)
    .lock__info
        transition: .3s
        display: flex
        border-left: .4rem solid var(--Blue)
        color: var(--PropertyTextColorWhite)
        max-width: 52.8rem
        width: 100%
        height: fit-content
        align-items: center
        justify-content: center
        border-radius: .5rem
        background: var(--SecondaryColorDark)
        padding: 1.6rem
        img
            margin-right: 2rem
        span
            font-size: 1.4rem
            line-height: 182%
            color: var(--SecondaryTextColorWhite)
.grid
    margin-top: 1rem
    display: grid
    grid-template-columns: repeat(auto-fill, 24.5rem)
    // grid-template-rows: 1fr 1fr
    gap: 1rem
.title
    font-weight: 600
    font-size: 2.4rem
    line-height: 3.6rem
    color: var(--PropertyTextColorWhite)
    margin-bottom: 1rem
.agreement
    font-size: 1.4rem
    line-height: 182%
    font-weight: 500
    color: var(--PropertyTextColorWhite)
    margin-bottom: 1rem
    a
        color: var(--Blue)
        transition: .3s
        &:hover
            color: #4498f8
        &:active
            color: var(--BlueActive)
@media screen and ( max-width: 500px )
    .grid
        grid-template-columns: repeat(auto-fill, 32.1rem)
    .book__shipment
        .due__now
            width: 38rem !important
        .lock__info
            width: 38rem !important