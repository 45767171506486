.main
    h1
        font-weight: 700
        font-size: 4.5rem
        color: var(--PropertyTextColorWhite)
        line-height: 6.8rem
        margin-bottom: 1.2rem
    .video
        margin-bottom: 4rem
        margin-left: 9rem
        max-width: 101rem
        width: 100%
        height: 57rem
        overflow: hidden
        position: relative
        border-radius: 1rem
        iframe
            max-width: 101rem
            width: 100%
            max-height: 100%
            border-radius: 1rem
            position: relative
            left: 0
            top: 0
            opacity: 1
    .block__template
        padding-top: 5rem
        padding-left: 3.6rem
        width: 35rem
        height: 31rem
        // height: fit-content
        border-radius: .5rem
        border: .1rem solid var(--InputColorWhite)
        display: flex
        flex-direction: column
        img
            margin-right: .5rem
        h2
            height: 6.2rem
            max-width: 24.3rem
            margin-top: 2.5rem
            margin-bottom: .8rem
            font-weight: 600
            font-size: 2.4rem
            color: var(--PropertyTextColorWhite)
            line-height: 130%
            margin-bottom: .8rem
        p
            height: 10.6rem
            max-width: 24.3rem
            font-weight: 500
            font-size: 1.4rem
            color: var(--SecondaryTextColorWhite)
            line-height: 162%
            max-width: 24.3rem
        div
            // padding-bottom: 1rem
            display: flex
            align-items: center
            justify-content: center
            width: 12.2rem
            height: 4rem
            border-radius: 100rem
            // margin-bottom: 2.5rem
            &:nth-child(1)
                background: #E9F3FE
            &:nth-child(2)
                background: #FEF8E9 !important
            &:nth-child(3)
                background: #FEE9EF !important
            h4
                font-weight: 600
                font-size: 1.4rem
                line-height: 130%

    .blocks
        justify-content: center
        display: flex
        align-items: center
@media screen and (max-width: 1280px)
    .main
        .video
            margin-left: 5rem
@media screen and (max-width: 1100px)
    .main
        .video
            margin-left: 2rem
@media screen and (max-width: 1000px)
    .main
        .video
            margin-left: .5rem
@media screen and (max-width: 980px)
    .main
        .video
            width: 94rem
@media screen and (max-width: 960px)
    .main
        .video
            width: 93rem
@media screen and (max-width: 870px)
    .main
        .video
            width: 84rem
        .block__template
            h2
                height: fit-content
            padding-right: 1rem
            padding-top: 2rem
            height: 32rem
@media screen and ( max-width: 500px )
    .main
        .video
            width: 35rem
            height: 30rem
        .blocks
            flex-direction: column !important
    