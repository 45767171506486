.button
    // width: 12rem
    // width: fit-content
    max-width: 12rem
    width: 100%
    // padding: 0 2.6rem
    height: 4.4rem
    background: var(--Blue)
    border-radius: .5rem
    border: none
    font-weight: 600
    font-size: 1.3rem
    cursor: pointer
    line-height: 2rem
    transition: .3s
    color: var(--MainScreen)
    &:hover
        background: var(--BlueHover)
    &:active
        background: var(--BlueActive)
    &[type=transparent]
        background: none
        color: var(--Blue)
        &:hover
            color: var(--BlueHover)
        &:active
            color: var(--BlueActive)
        span
            color: var(--SecondaryTextColorWhite)
    &:disabled
        background: grey
        &:hover
            background: #aa9898
        &:active
            background: #747070