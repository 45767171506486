.steps
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 1.2rem
    &-step
        transition: .3s
        width: 4rem
        height: 4rem
        margin-right: 1.2rem
        border-radius: 50%
        display: flex
        resize: none
        align-items: center
        justify-content: center
        background: var(--InputColorWhite)
        font-weight: 600
        font-size: 1.4rem
        line-height: 130%
        user-select: none
        color: var(--MainScreen)
        span
            color: var(--MainScreen) !important
    img
        display: none
    .wrapper
        width: 100%
        max-width: none
        display: flex
        align-items: center
        span
            transition: .3s
            font-weight: 600
            font-size: 1.4rem
            line-height: 182%
            color: var(--InputColorWhite)
    .active
        div
            transition: .3s
            background: var(--Blue)
        span
            color: var(--PropertyTextColorWhite)
        hr
            border-color: var(--Blue)
    .confirmed
        hr
            border-color: var(--Blue)
        img
            display: block
        div
            transition: .3s
            background: var(--Blue)
        span
            &:first-child
                display: none
            color: var(--Blue)
    hr
        transition: .3s
        width: 12rem
        margin: 0 1.6rem
        height: 0
        border: .1rem solid var(--InputColorWhite)
        &.active
            border-color: var(--Blue)
@media screen and (max-width: 500px) 
    .steps
        hr
            width: 5rem
    .wrapper
        span.unactive
            display: none
    .steps
        &-step
            margin-right: 0