.react-calendar {
    z-index: 1000;
    width: 31rem;
    max-width: 100%;
    background-color: var(--SecondaryColorDark);
    color: var(--PropertyTextColorWhite);
    border-radius: 8px;
    line-height: 1.125em;
    border: .1rem solid var(--InputColorWhite);
}

.react-calendar__navigation button {
    color: #6f48eb;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #DCE2F9;
}

.react-calendar__navigation button[disabled] {
    background: var(--DisabledButton);
}

abbr[title] {
    text-decoration: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    color: var(--PropertyTextColorWhite);
    background: var(--TileBgActive);
    /* border-radius: 6px; */
}

.react-calendar__tile:disabled {
    background: var(--DisabledButton);
    /* color: var(--SecondaryColorDark); */
}

.react-calendar__month-view__days__day {
    color: var(--PropertyTextColorWhite);
}

.react-calendar__month-view__days__day:disabled {
    opacity: .5;
}

.react-calendar__month-view__days__day--weekend{
    color: red;
}

.react-calendar__tile--now {
    background: #6f48eb33;
    color: var(--GreenDiscount);
    font-weight: bold;
    color: #6f48eb;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    font-weight: bold;
    color: #6f48eb;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: var(--CalendarHeader);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}

.react-calendar__tile--active {
    background: #6f48eb;
    border-radius: 6px;
    font-weight: bold;
    color: var(--PropertyTextColorWhite);
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #6f48eb;
    color: var(--PropertyTextColorWhite);
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background: var(--TileBgActive);
}

.react-calendar__tile--range {
    background: var(--TileBg);
    border-radius: 0;
}

.react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #6f48eb;
    color: var(--PropertyTextColorWhite);
}

.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #6f48eb;
    color: var(--PropertyTextColorWhite);
}