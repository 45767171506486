.our-services
    height: fit-content
    .title
        color: var(--PropertyTextColorWhite)
        font-weight: 700
        font-size: 4.5rem
        line-height: 6.8rem
    .subtitle
        margin-top: 1.2rem
        font-weight: 700
        font-size: 2rem
        line-height: 130%
        color: var(--PropertyTextColorWhite)
    .p-title
        max-width: 47.3rem
        height: fit-content
        margin-top: 1.2rem
        font-weight: 500
        font-size: 1.4rem
        line-height: 130%
        color: var(--SecondaryTextColorWhite)
        margin-bottom: 5rem
    .right__template
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        height: fit-content
        height: 36.5rem
        gap: .5rem
        .image__pattern
            position: relative
            img
                &:nth-child(1)
                    right: 0
                    position: relative
                    width: 60rem
                    height: 36.5rem
                    mask-position: center
                    mask-repeat: no-repeat
                    mask-size: 100%
                    // mask-image: url(/static/pattern/rightblock.svg)
                    mask-image: url(./../../assets/pattern/rightblock.svg)
                    mask-mode: alpha
                    z-index: 10
                &:nth-child(2)
                    position: absolute
                    bottom: -1.5rem
                    right: -1.5rem
                &:nth-child(3)
                    top: -3rem
                    z-index: 0
                    position: absolute
                    right: -2.5rem
        .text
            position: relative
            width: 100%
            h3
                font-weight: 600
                font-size: 2.4rem
                line-height: 130%
                color: var(--PropertyTextColorWhite)
            p
                margin-top: 1.2rem
                max-width: 49.5rem
                height: fit-content
                color: var(--SecondaryTextColorWhite)
                font-weight: 500
                font-size: 1.4rem
                line-height: 162%
    .center__template
        margin: 3rem 0
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        height: fit-content
        height: 36.5rem
        .image__pattern
            position: relative
            img
                &:nth-child(1)
                    right: 0
                    position: relative
                    width: 60rem
                    height: 36.5rem
                    mask-position: center
                    mask-repeat: no-repeat
                    mask-size: 100%
                    mask-image: url(./../../assets/pattern/leftblock.svg)
                    mask-mode: alpha
                    z-index: 10
                &:nth-child(2)
                    top: -3rem
                    left: -2.5rem
                    z-index: 0
                    position: absolute
        .text
            position: relative
            h3
                font-weight: 600
                font-size: 2.4rem
                line-height: 130%
                color: var(--PropertyTextColorWhite)
            p
                margin-top: 1.2rem
                max-width: 49.5rem
                height: fit-content
                color: var(--SecondaryTextColorWhite)
                font-weight: 500
                font-size: 1.4rem
                line-height: 162%
@media screen and ( max-width: 500px )
    .our-services
        .right__template, .center__template
            flex-direction: column
            .image__pattern
                img
                    margin-top: 3rem
                    &:first-child
                        width: 50rem !important
                        height: 30rem !important
        .center__template
            margin-top: 20rem !important
            margin-bottom: 20rem !important