.tooltip
    display: flex
    align-items: center
    gap: 1rem
    font-weight: 600
    font-size: 1.6rem
    line-height: 182%
    white-space: pre-line
    // height: fit-content
    color: var(--PropertyTextColorWhite)
    .svg
        width: 2rem
        height: 2rem
        transition: .3s
        display: flex
        align-items: center
        justify-content: center
        background: var(--SecondaryTextColorWhite)
        border-radius: 50%
        white-space: pre-line
        &:hover
            opacity: .5
            cursor: pointer
    .text
        padding: 0
        margin: 0 !important
        display: flex
        align-items: center
        white-space: pre-line
.tip
    display: block
    width: 35rem
    line-height: 1.8rem
    font-size: 1rem
