.row
    display: flex
    align-items: flex-end
    justify-content: space-between
    gap: 1.6rem
    margin-bottom: 1.6rem
.row__two
    display: flex
    align-items: flex-start
    gap: 1.6rem
.column
    display: flex
    width: 24.5rem
    flex-direction: column
    align-items: flex-start
.mt
    margin-top: .3rem
.checkboxes
    margin-top: 1rem
    display: flex
    align-items: center
    gap: 1.5rem
@media screen and ( max-width: 500px )
    .row
        align-items: normal
        flex-direction: column
        input, label, button
            width: 100% !important
            max-width: unset !important
    .row__two
        flex-direction: column !important
        input, label, button
            width: 100% !important
            max-width: unset !important
    .column
        width: 100% !important
        input, label, button
            width: 100% !important
            max-width: unset !important