.header
    margin: 0 auto
    margin-bottom: 8.8rem
    max-width: 121.6rem
    width: 100%
    z-index: 11
    .logo
        width: 6rem
        height: 4.4rem
    nav
        position: relative
        z-index: 100
        height: 8.4rem
        display: flex
        justify-content: space-between
        align-items: center
    .first
        display: flex
        align-items: center
    &-links
        flex-wrap: wrap
        display: flex
        align-items: center
        justify-content: center
        gap: 4.4rem
        margin-left: 14.6rem
    .link
        font-weight: 400
        font-size: 1.3rem
        line-height: 2rem
        transition: .3s
        color: var(--SecondaryTextColorWhite)
        &:hover
            color: var(--BlueHover)
        &:active
            color: var(--BlueActive)
    .active
        color: var(--Blue)
    .theme
        display: flex
        align-items: center
        gap: 1.5rem
        cursor: pointer
        svg
            transition: .3s
            width: 2rem
            height: 2rem
            path
                transition: .3s
    .phone
        font-weight: 500
        font-size: 1.4rem
        line-height: 2.1rem
        transition: .3s
        color: var(--Blue)
        z-index: 100
        &:hover
            color: var(--BlueHover)
        &:active
            color: var(--BlueActive)
.burger_menu
    display: none
    .hr
        margin-right: 2rem
        display: block
        width: 3.5rem
        height: .4rem
        margin-bottom: .5rem
        position: relative
        background: #cdcdcd
        border-radius: .4rem
        z-index: 1
        transform-origin: .4rem 0px
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease
        &:first-child
            transform-origin: 0% 0%
        &:nth-last-child(2)
            transform-origin: 0% 100%
    input
        display: block
        width: 4rem
        height: 3.2rem
        position: absolute
        top: 2rem
        right: .7rem
        cursor: pointer
        opacity: 0
        z-index: 2
        -webkit-touch-callout: none
        &:checked
            ~.hr
                opacity: 1
                transform: rotate(45deg) translate(.1rem, .1rem)
            ~.hr:nth-last-child(2)
                transform: rotate(-45deg) translate(-.1rem, .2rem)
            ~.hr:nth-last-child(3)
                background: #232323
                opacity: 0
                transform: rotate(0deg) scale(0.2, 0.2)
            ~.menu
                opacity: 1
                pointer-events: all
                transition: all 0.5s ease
    .menu
        border: 1px solid var(--InputColorWhite)
        position: absolute
        opacity: 0
        width: 100%
        height: 20rem
        background: var(--SecondaryColorDark)
        z-index: 1000
        padding: 2rem
        margin-top: .5rem
        transition: all 0.5s ease
        left: 0
        pointer-events: none
        display: flex
        align-items: flex-start
        justify-content: space-between
    .menu_links
        display: flex
        flex-direction: column
        gap: 3rem
    .menu_themes
        display: flex
        gap: 3rem
        svg
            cursor: pointer

@media screen and (max-width: 820px)
    .adaptive
        display: none
    header
        nav
            .burger_menu
                display: block !important

@media screen and (max-width: 900px)
    .header-links
        margin-left: 6rem
