.row
    display: flex
    align-items: flex-end
    justify-content: space-between
    gap: 1.6rem
    margin-bottom: 1.6rem
.foo
    background: var(--Blue)
    width: 20rem
    height: 20rem
    z-index: 1000000
.tooltip
    max-width: 40rem
    // position: relative
    top: 0
.checkboxes
    margin-top: 1.6rem
    display: flex
    align-items: center
    gap: 1.5rem
@media screen and ( max-width: 500px )
    .row
        flex-direction: column
        align-items: normal
        input, label, button
            width: 100% !important
            max-width: unset !important