:root
    --SecondaryTextColorWhite: #8B90A4
    --MainScreen: #FFFFFF
    --Blue: #1E7FF0
    --BlueHover: #4498f8
    --BlueActive: #3070b9
    --SecondaryColorWhite: #DCE2F9
    --PropertyTextColorWhite: #2B2B2B
    --Btn: #000
    --Dark: #000
    --TipBg: #FCFCFC
    --DarkSecondary: #2a2a2a
    --StrokeBlockWhite: #DCE2F9
    --InputColorWhite: #DCE2F9
    --StrokeBtn: #A6A6A6
    --SecondaryTextDark: #80838B
    --SecondaryColorDark: #fff
    --StrokeInputDark: #3A3E49
    --BackgroundDark: #1B1B1B
    --RedDayCalendar: #CC5757
    --DetailsBgDark: linear-gradient(180deg, rgba(33, 33, 33, 0.25) 0%, #212121 138.87%)
    --StrokeDetails: #353535
    --StrokeMapDark: #383838
    --BgImage: linear-gradient(270deg, rgba(196, 196, 196, 0.1) 0%, rgba(196, 196, 196, 0) 100%)
    --ThreeStep: #F01E5D
    --TwoStep: #F0B51E
    --NotificationDark: #2D2D2D
    --GreenDiscount: #26A642
    --CalendarHeader: #e6e6e6
    --TileBg: #DCE2F9
    --TileBgActive: #ccd1e7
    --DisabledButton: rgb(215, 215, 245)

html, body
    font-size: 10px
    overflow-x: hidden
.tooltip
    max-width: 45rem
    line-height: 1.8rem
    font-size: 1rem
    display: flex
    align-items: flex-start
    span
        line-height: 1.4rem
        font-size: 1.2rem
    .multi-line
        text-align: left !important
*,
*::after,
*::before
    margin: 0
    padding: 0
    box-sizing: border-box
    text-decoration: none
    list-style: none
    font-family: 'Poppins'
    font-style: normal
    transition: color, background-color, .3s

#root
    display: flex
    flex-direction: column
    height: 100vh
    overflow-x: hidden
.pac-container
    background-color: #000
    .pac-item
        background-color: #000 !important

.App
    // transition: .3s
    width: 100vw
    height: 100vh
    position: relative
    overflow-x: hidden
    display: flex
    flex-direction: column
    // background-size: 63% 120%
.App--dark
    @extend .App
    --PropertyTextColorWhite: #fff
    --SecondaryColorDark: #212121
    --StrokeInputDark: #3A3E49
    --InputColorWhite: #3A3E49
    --StrokeBlockWhite: #3A3E49
    --CalendarHeader: #353232
    --TileBg: #363738
    --DisabledButton: rgb(42, 42, 46)
    --TileBgActive: #636f7c
    background: var(--BackgroundDark)

body.white
    .pac-container
        color: #FFF !important
        background-color: #fff
        .pac-item
            cursor: pointer
            background-color: #fff !important
            &:hover
                background: #DCE2F9 !important
            .pac-item-query
                color: #000 !important

body.dark
    .pac-container
        &::after
            background-image: url(./powered_by_google_on_non_white_hdpi.png)
        color: #FFF !important
        background-color: #212121
        .pac-item
            border-top: 1px solid #3A3E49 !important
            background-color: #212121 !important
            cursor: pointer
            &:hover
                background: #363535 !important
            .pac-item-query
                color: #fff !important
    .rc-anchor-container
        background: #000 !important

img, svg
    user-select: none
@media screen and (min-width: 2300px)
    html, body
        font-size: 12px
@media screen and (min-width: 3000px)
    html, body
        font-size: 16px
@media screen and (max-width: 500px)
    .App
        padding: 0 1rem