.details
    // width: 37.6rem
    max-width: 37.6rem
    width: 100%
    height: 55.6rem
    background: var(--BgImage)
    padding: 3rem 3.7rem 0 3.2rem
    position: relative
    h1
        font-weight: 600
        font-size: 2.4rem
        line-height: 3.6rem
        color: var(--PropertyTextColorWhite)
        margin-bottom: 1rem
    .body
        width: 100%
        display: flex
        // justify-content: space-between
        align-items: flex-start
        // height: fit-content
        position: relative
        gap: 2.5rem
        margin-bottom: 1rem
    ul
        li, p
            white-space: nowrap
            margin-bottom: 1rem
            font-weight: 500
            font-size: 1.4rem
            line-height: 182%
        &:first-child
            li
                // margin-right: 4rem
            li, p, div
                color: var(--SecondaryTextColorWhite)
                &:nth-child(7), &:nth-child(8)
                    line-height: 117%
                    margin-bottom: 3.6rem
                &:nth-child(7)
                    margin-top: 2.6rem
                    p
                        line-height: 117%
                &:nth-child(8)
                    p
                        line-height: 117%
        &:last-child
            li, p
                color: var(--PropertyTextColorWhite)
                &:nth-child(7), &:nth-child(8)
                    line-height: 117%
                    margin-top: 1.5rem
    hr
        position: relative
        position: absolute
        width: 100%
        border: none
        left: 0
        border-top: .2rem dashed #E2E4E8
    .price
        position: absolute
        bottom: 4.6rem
        font-weight: 500
        font-size: 1.6rem
        line-height: 130%
        color: var(--SecondaryTextColorWhite)
        display: flex
        align-items: center
        gap: 1.2rem
        strong
            font-weight: 600
            font-size: 2.4rem
            line-height: 130%
            color: var(--PropertyTextColorWhite)
@media screen and (max-width: 935px) 
    .details
        max-width: 32.6rem
@media screen and ( max-width: 500px )
    .details
        max-width: unset
        
    