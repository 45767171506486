.custom__checkbox
    align-items: center
    display: flex
    flex-direction: row
    font-weight: 500
    font-size: 1.4rem
    line-height: 182%
    width: fit-content
    color: var(--PropertyTextColorWhite)
    input
        display: none
    label
        white-space: nowrap
        display: flex
        align-items: center
        cursor: pointer
        font-weight: 500
        &::before
            content: " "
            display: inline-block
            width: 2rem
            height: 2rem
            border: .1rem solid var(--SecondaryTextColorWhite)
            background-color: transparent
            border-radius: .5rem
            margin-right: 1rem
            vertical-align: middle
            transition: 0.2s
    input:checked + label::before
        transition: 0.2s
        background-color: var(--Blue)
        background-image: url(./swoosh.svg)
        background-repeat: no-repeat
        background-position: center
        // border: .1rem solid var(--MainScreen)
        border: transparent
