.your__info
    display: flex
    flex-direction: column
    h1
        font-weight: 600
        font-size: 2.4rem
        line-height: 3.6rem
        color: var(--PropertyTextColorWhite)
        margin-bottom: 1rem
    h3
        font-weight: 600
        font-size: 1.6rem
        line-height: 182%
        color: var(--PropertyTextColorWhite)
        margin-bottom: 1.2rem
    .checkboxes
        display: flex
        align-items: center
        gap: 3.5rem
        flex-wrap: wrap
    .column
        margin-top: 1rem
        display: flex
        flex-direction: column
        gap: 1rem
    button
        margin-top: 1rem
    @media screen and (max-width: 990px)
        // display: flex
