.row
    display: flex
    align-items: center
    justify-content: space-between
    gap: 2rem
    margin-bottom: 1.6rem
.main
    height: 20rem
.row__two
    display: flex
    margin: 0
    // align-items: flex-end
    position: relative
    gap: 1.6rem
    justify-content: left
.checkbox__one
    position: relative
    top: -8rem
    left: 13.5rem
.checkbox__two
    position: relative
    top: -10.5rem
    left: 46rem
.private
    display: flex
    align-items: center
    span
        font-size: 1.4rem
        line-height: 132%
        font-weight: 500
        color: var(--PropertyTextColorWhite)
        margin-left: 1rem
    a
        color: var(--Blue)
        transition: .3s
        &:hover
            color: #4498f8
        &:active
            color: var(--BlueActive)
@media screen and ( max-width: 500px )
    .row
        flex-direction: column
        input, label, button
            width: 100% !important
            max-width: unset !important
    .checkbox__one, .checkbox__two
        top: unset
        left: unset
    .calendar
        margin-bottom: 2rem
    .row__two
        position: static !important
        // margin-top: 20rem
        flex-direction: column
    .private
        margin-top: 25rem
        position: static !important
        button
            max-width: unset !important
        flex-direction: column
        span
            margin-left: 0 !important
    .form
        height: fit-content !important