.layout
    margin: auto
    width: 100%
    z-index: 10
    .main
        z-index: 10
        margin: auto
        max-width: 121.6rem
        width: 100%
        height: fit-content
        display: flex
        justify-content: center
        flex-direction: column
