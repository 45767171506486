@keyframes cars
    0%
        margin-left: 0px
    100%
        margin-left: -1000rem
.container__auto
    position: relative
    z-index: 5
    height: 12rem
    left: 0
    pointer-events: none
    .auto
        position: relative
        z-index: 5
        animation: cars 200s linear infinite alternate 0s forwards