.label
    display: flex
    flex-direction: column
    width: 100%
    max-width: 24.5rem
    font-weight: 600
    font-size: 1.6rem
    line-height: 182%
    color: var(--PropertyTextColorWhite)
    position: relative
    svg
        // top: 4.8rem
        top: 2rem
        transition: .3s
        position: absolute
        right: 1rem
        cursor: pointer
        &:hover
            opacity: .5
.input
    margin-top: 1rem
    height: 4.4rem
    width: 100%
    border: none
    color: var(--SecondaryTextDark)
    outline: none
    background: transparent
    border: .1rem solid var(--InputColorWhite)
    border-radius: .5rem
    padding: 1rem 2.3rem 1rem 2.5rem
    font-weight: 500
    font-size: 1.4rem
    line-height: 182%
    transition: .3s
    color: var(--SecondaryTextColorWhite)
    // &::-webkit-datetime-edit-year-field:not([aria-valuenow]), &::-webkit-datetime-edit-month-field:not([aria-valuenow]),&::-webkit-datetime-edit-day-field:not([aria-valuenow])
    //     color: transparent
    position: relative

    &:focus
        border-color: var(--Blue)
        color: var(--PropertyTextColorWhite)
    &:hover
        // border-color: var(--StrokeBlockWhite)