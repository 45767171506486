@keyframes full__animation
    0%
        opacity: 0
    100%
        opacity: 1

.bg__image
    pointer-events: none
    z-index: 0
    // opacity: 0
    position: absolute
    left: 37.4%
    width: 64%
    user-select: none
.image
    pointer-events: none
    user-select: none
    animation: full__animation 1.5s 1
    z-index: 0
    width: 98.2%
    object-fit: cover
    // transition: .3s
.bg__image__car
    pointer-events: none
    z-index: 0
    position: absolute
    left: 55.4%
    top: 30%
    width: 64%
    user-select: none
.image__car
    pointer-events: none
    user-select: none
    z-index: 0
    width: 70.2%
    animation: full__animation 1.5s 1
    object-fit: cover
    // transition: .3s
    // -webkit-transform: scaleX(-1)
    // transform: scaleX(-1)
.bg__image__calendar
    pointer-events: none
    z-index: 0
    position: absolute
    left: 40.4%
    top: 24%
    width: 64%
    user-select: none
.image__calendar
    pointer-events: none
    user-select: none
    z-index: 0
    animation: full__animation 2.5s 1
    width: 70.2%
    object-fit: cover
    -webkit-transform: scaleX(-1)
    transform: scaleX(-1)
@media screen and (max-width: 1024px)
    .bg__image, bg__image__car, bg__image__calendar, .image__calendar, .image__car
        display: none !important
