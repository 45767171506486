.contact-us
    h1
        font-weight: 700
        font-size: 4.5rem
        color: var(--PropertyTextColorWhite)
        line-height: 6.8rem
    .main
        display: flex
        align-items: center
    .image
        margin-right: 5rem
        width: 48.7rem
        height: 51rem
        background: var(--Blue)
        border-radius: .5rem 0 0 .5rem
        padding-top: 6rem
        padding-left: 5rem
        display: flex
        flex-direction: column
        // justify-content: space-between
        h2
            max-width: 33rem
            // height: 195px
            color: #fff
            font-weight: 700
            font-size: 4.3rem
        .something
            position: relative
            bottom: 0
            display: flex
            img
                position: absolute
                right: 0
                &:first-child
                    top: 7rem
    form
        h4
            color: var(--PropertyTextColorWhite)
            font-weight: 700
            font-size: 2.4rem
            line-height: 182%
    .inputs
        display: flex
        gap: 2rem
        input
            margin-top: 2rem
    button
        margin-top: 2rem
@media screen and (max-width: 1100px)
    .contact-us
        .image
            width: 40rem
            h2
                font-size: 3.8rem
            .something
                img
                    &:first-child
                        top: 9rem
@media screen and (max-width: 940px)
    .contact-us
        .image
            padding-left: 2rem
            width: 40rem
            h2
                font-size: 3.3rem
            .something
                img
                    &:first-child
                        top: 11.4rem
@media screen and ( max-width: 500px )
    .main
        flex-direction: column
        .inputs
            flex-direction: column
            button
                max-width: unset !important
            textarea
                margin-top: 0 !important
    .contact-us
        .image
            margin-right: 0
            padding: 3rem !important
            width: 35rem
            height: 55rem
            border-radius: .7rem
            h2
                font-size: 4rem
            img
                display: none