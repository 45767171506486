.choose
    display: flex
    flex-direction: column
    align-items: flex-start
    position: relative
.blocks
    display: flex
    align-items: flex-start
    justify-content: space-between
    gap: 2rem
    margin-bottom: 2rem
.template
    transition: .3s
    width: 100%
    max-width: 39.2rem
    // width: 39.2rem
    // height: 26.2rem
    min-height: 27.2rem
    height: 100%
    background: var(--SecondaryColorDark)
    border: .1rem solid var(--StrokeBlockWhite)
    border-radius: 1rem
    padding: 2.9rem
    display: flex
    flex-direction: column
    align-items: center
    cursor: pointer
    h3
        font-weight: 600
        height: 2.5rem
        gap: 1.1rem
        font-size: 1.4rem
        line-height: 182%
        color: var(--PropertyTextColorWhite)
        display: flex
        align-items: center
        margin-bottom: 2.4rem
    h1
        margin-bottom: 2.4rem
        font-weight: 600
        font-size: 4.5rem
        line-height: 130%
        color: var(--PropertyTextColorWhite)
    p
        text-align: center
        font-weight: normal
        font-size: 1.4rem
        line-height: 182%
        color: var(--SecondaryTextColorWhite)
.first__tip
    max-width: 80.4rem
    min-height: 11.8rem
    background: var(--SecondaryColorDark)
    border-radius: .5rem
    border-left: .4rem solid var(--Blue)
    display: flex
    flex-wrap: wrap
    padding-left: 4.5rem
    width: 100%
    flex-direction: column
    justify-content: space-around
    margin-bottom: 2rem
    h1
        font-weight: 600
        font-size: 1.6rem
        line-height: 182%
        color: var(--PropertyTextColorWhite)
    p
        font-size: 1.4rem
        line-height: 182%
        padding-right: 3rem
        color: var(--SecondaryTextColorWhite)
        a
            color: var(--Blue) !important
            transition: .3s
            color: var(--SecondaryTextColorWhite)
            &:hover
                color: var(--BlueHover)
            &:active
                color: var(--BlueActive)
.second__tip
    margin-top: 10rem
    position: relative
    max-width: 35.5rem
    width: 100%
    height: 6.3rem
    background: var(--SecondaryColorDark)
    border-radius: .5rem
    opacity: 0
    transition: .3s
    display: flex
    align-items: center
    justify-content: center
    animation: animate 7s 1
    border-left: .4rem solid var(--Blue)
    span
        font-weight: 500
        font-size: 1.4rem
        line-height: 182%
        color: var(--PropertyTextColorWhite)
@keyframes animate
    0%
        opacity: .5
        left: 100rem
    40%
        left: 45rem
    80%
        left: 45rem
        opacity: .9
    100%
        left: 45rem
        opacity: 0
@media screen and (max-width: 960px)
    .second__tip
        @keyframes animate
            0%
                opacity: .5
                left: 100rem
            40%
                left: 15rem
            80%
                left: 15rem
                opacity: .9
            100%
                left: 15rem
                opacity: 0
@media screen and ( max-width: 500px )
    .blocks
        flex-direction: column
    .second__tip
        @keyframes animate
            0%
                opacity: .5
                left: 30rem
            40%
                left: 1rem
            80%
                left: 1rem
                opacity: .9
            100%
                left: 1rem
                opacity: 0