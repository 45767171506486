.footer
    width: 100%
    margin-top: 10rem
    footer
        max-width: 121.6rem
        width: 100%
        margin: 0 auto
        height: 8.8rem
        display: flex
        align-items: center
        justify-content: space-between
        span
            font-weight: 500
            font-size: 1.4rem
            line-height: 2.1rem
            color: var(--SecondaryTextColorWhite)
        div
            span
                margin-left: 4rem
                a
                    color: var(--Blue)
                    transition: .3s
                    &:hover
                        color: #4498f8
                    &:active
                        color: var(--BlueActive)
@media screen and ( max-width: 500px )
    .footer
        footer
            flex-direction: column
            span
                margin-left: 2rem
            div
                display: flex
                flex-direction: column
                justify-content: center !important
                align-items: center !important
                gap: 2rem
                margin-bottom: 2rem
                span
                    margin-left: 0
    