.steps
    display: flex
    align-items: flex-start
    justify-content: space-between
    // margin-bottom: 1.2rem
    &-step
        transition: .3s
        color: var(--MainScreen)
        width: 4rem
        height: 4rem
        // margin-right: 1.2rem
        border-radius: 50%
        display: flex
        resize: none
        align-items: center
        justify-content: center
        background: var(--InputColorWhite)
        font-weight: 600
        font-size: 1.4rem
        line-height: 130%
        user-select: none
        color: var(--MainScreen)
        span
            color: var(--MainScreen) !important
    img
        display: none
    .wrapper
        width: 100%
        max-width: none
        display: flex
        align-items: center
        span
            transition: .3s
            font-weight: 600
            font-size: 1.4rem
            line-height: 182%
            color: var(--InputColorWhite)
        &:nth-child(4)
            hr
                margin-top: -6.5rem
    .active
        div
            div
                transition: .3s
                background: var(--Blue)
        span
            color: var(--PropertyTextColorWhite)
        hr
            border-color: var(--Blue)
    .confirmed
        hr
            border-color: var(--Blue)
        img
            display: block
        div
            div
                transition: .3s
                background: var(--Blue)
        span
            color: var(--Blue)
            &:first-child
                display: none
    hr
        transition: .3s
        width: 6.7rem
        // margin: 0 1.6rem
        height: 0
        border: .1rem solid var(--InputColorWhite)
        margin-top: -4rem
        &.active
            border-color: var(--Blue)
    .wrapper__two
        display: flex
        flex-direction: column
        align-items: center
    .text
        // width: fit-content
        margin-top: 1rem
        min-width: 6.3rem
        text-align: center

@media screen and (max-width: 1000px)
    .steps
        .wrapper
            hr
                width: 5rem
@media screen and (max-width: 950px)
    .steps
        .wrapper
            span
                font-size: 1.25rem
@media screen and (max-width: 870px)
    .steps
        .wrapper
            span
                font-size: 1.25rem
            hr
                margin: 0 !important
                margin: 0 .9rem !important
        .text
            display: none
@media screen and ( max-width: 500px )
    .steps
        .wrapper
            hr
                width: 2rem
    