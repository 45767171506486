.textarea
    max-width: 30.3rem
    width: 100%
    height: 14rem
    border-radius: .5rem
    background: transparent
    margin-top: 2rem
    margin-bottom: 2rem
    padding: 2rem
    outline: none
    transition: color, background-color, border-color .3s
    resize: vertical
    &:focus
        border: .1rem solid var(--Blue)
    color: var(--PropertyTextColorWhite)
    border: .1rem solid var(--InputColorWhite)