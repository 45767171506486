.layout
    display: flex
    flex-direction: column
    .steps
        width: fit-content
        // margin-left: 4.5rem

@media screen and ( max-width: 500px )
    .layout
        label, input, button, textarea
            max-width: unset !important
            width: 100% !important
