.form
    padding: 3rem
    width: 70.1rem
    height: 29.1rem
    background: var(--SecondaryColorDark)
    // background: var(--MainScreen)
    border: .1rem solid var(--StrokeBlockWhite)
    border-radius: .5rem
    // height: fit-content
    z-index: 10

@media screen and (max-width: 500px)
    .form
        width: 100%
        max-width: 36rem
        height: fit-content !important
