.text
    z-index: 10
    h1
        font-weight: 700
        font-size: 4.5rem
        height: 20.4rem
        width: 70.1rem
        line-height: 6.8rem
        // color: var(--MainScreen)
        color: var(--PropertyTextColorWhite)
        margin-bottom: 1.2rem
    h3
        width: 61.8rem
        height: 9rem
        font-family: 'Poppins'
        font-style: normal
        font-weight: 500
        font-size: 1.4rem
        line-height: 2.5rem
        color: var(--SecondaryTextColorWhite)
@media screen and (max-width: 500px)
    .text
        margin-bottom: 2rem
        h1
            max-width: 70.1rem
            width: 100%
            height: fit-content
        h3
            max-width: 61.8rem
            width: 100%
            height: fit-content