.wrapper
    margin: auto
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 0rem
    a
        color: var(--Blue) !important
        transition: .3s
        color: var(--SecondaryTextColorWhite)
        &:hover
            color: var(--BlueHover)
        &:active
            color: var(--BlueActive)
.thank__you
    margin-top: 2rem
    gap: 1rem
    display: flex
    flex-direction: column
    align-items: center
    h1
        font-weight: 600
        font-size: 2.4rem
        display: flex
        align-items: center
        gap: 1.2rem
        line-height: 182%
        color: var(--PropertyTextColorWhite)
    h4
        font-weight: 500
        font-size: 1.4rem
        line-height: 182%
        color: var(--PropertyTextColorWhite)
        span
            font-weight: 500
            font-size: 1.4rem
            line-height: 182%
            color: var(--Blue)
    p
        text-align: center
        max-width: 32.3rem
        width: 100%
        height: fit-content
        font-weight: 400
        font-size: 1.4rem
        line-height: 182%
        color: var(--SecondaryTextColorWhite)
    a
        font-weight: 500
        font-size: 1.2rem
        // line-height: 2.1rem
        transition: .3s
        color: var(--Blue)
        z-index: 100
        &:hover
            color: var(--BlueHover)
        &:active
            color: var(--BlueActive)
